const serviceDuration = [
  { title: '5min', value: 300 },
  { title: '10min', value: 600 },
  { title: '15min', value: 900 },
  { title: '20min', value: 1200 },
  { title: '25min', value: 1500 },
  { title: '30min', value: 1800 },
  { title: '35min', value: 2100 },
  { title: '40min', value: 2400 },
  { title: '45min', value: 2700 },
  { title: '50min', value: 3000 },
  { title: '55min', value: 3300 },
  { title: '1h', value: 3600 },
  { title: '1h 5min', value: 3900 },
  { title: '1h 10min', value: 4200 },
  { title: '1h 15min', value: 4500 },
  { title: '1h 20min', value: 4800 },
  { title: '1h 25min', value: 5100 },
  { title: '1h 30min', value: 5400 },
  { title: '1h 35min', value: 5700 },
  { title: '1h 40min', value: 6000 },
  { title: '1h 45min', value: 6300 },
  { title: '1h 50min', value: 6600 },
  { title: '1h 55min', value: 6900 },
  { title: '2h', value: 7200 },
  { title: '2h 5min', value: 7500 },
  { title: '2h 10min', value: 7800 },
  { title: '2h 15min', value: 8100 },
  { title: '2h 20min', value: 8400 },
  { title: '2h 25min', value: 8700 },
  { title: '2h 30min', value: 9000 },
  { title: '2h 35min', value: 9300 },
  { title: '2h 40min', value: 9600 },
  { title: '2h 45min', value: 9900 },
  { title: '2h 50min', value: 10200 },
  { title: '2h 55min', value: 10500 },
  { title: '3h', value: 10800 },
  { title: '3h 5min', value: 11100 },
  { title: '3h 10min', value: 11400 },
  { title: '3h 15min', value: 11700 },
  { title: '3h 20min', value: 12000 },
  { title: '3h 25min', value: 12300 },
  { title: '3h 30min', value: 12600 },
  { title: '3h 35min', value: 12900 },
  { title: '3h 40min', value: 13200 },
  { title: '3h 45min', value: 13500 },
  { title: '3h 50min', value: 13800 },
  { title: '3h 55min', value: 14100 },
  { title: '4h', value: 14400 },
  { title: '4h 5min', value: 14700 },
  { title: '4h 10min', value: 15000 },
  { title: '4h 15min', value: 15300 },
  { title: '4h 20min', value: 15600 },
  { title: '4h 25min', value: 15900 },
  { title: '4h 30min', value: 16200 },
  { title: '4h 35min', value: 16500 },
  { title: '4h 40min', value: 16800 },
  { title: '4h 45min', value: 17100 },
  { title: '4h 50min', value: 17400 },
  { title: '4h 55min', value: 17700 },
  { title: '5h', value: 18000 }
];

export default serviceDuration;
