<template>
  <div class="sale-item box">
    <header class="box-header columns is-mobile">
      <div
        class="box-header-title column is-two-thirds-mobile
              is-three-quarters-desktop is-three-quarters-tablet"
      >
        <p class="title is-5">
          {{ cartItem.name }}
        </p>
        <p
          v-if="!!cartItem.employee && !!cartItem.service_category_id"
          class="box-header-subtitle column is-11 subtitle is-6 has-text-grey"
        >
          {{ duration(cartItem.duration) }} com {{ cartItem.employee.name }}
        </p>
      </div>
      <div class="column item-price">
        <p class="is-size-6 has-text-dark has-text-weight-semibold">
          {{ displayMoney(cartItem.price) }}
        </p>
      </div>
      <span class="delete-item column">
        <button
          type="button"
          class="delete"
          @click="$emit('remove-item')"
        ></button>
      </span>
    </header>
    <div class="box-content field columns is-mobile is-multiline">
      <div class="column control is-one-quarter-mobile">
        <label class="label has-text-weight-medium">Qtd</label>
        <div class="control">
          <input
            :value="cartItem.quantity"
            :disabled="!!cartItem.service_category_id"
            @change="setQuantity"
            class="input"
            type="text"
          />
        </div>
      </div>
      <div
        class="column control is-half-desktop is-one-third-tablet is-three-quarters-mobile"
      >
        <label class="label">Profissional</label>
        <div class="control">
          <b-autocomplete
            v-model="name"
            :data="filteredDataObj"
            :open-on-focus="true"
            ref="autocomplete"
            field="name"
            placeholder="Digite para pesquisar"
            @select="setEmployee"
          >
            <template slot="header">
              <a>
                <span> Adicionar novo profissional </span>
              </a>
            </template>
            <template slot="empty">
              Sem resultados para {{ name }}
            </template>
          </b-autocomplete>
        </div>
      </div>
      <div class="column is-one-fifth-desktop is-one-fourth-tablet control">
        <label class="label">Preço</label>
        <div class="control has-icons-left">
          <input
            :value="displayRawMoney(cartItem.price)"
            @input="setPrice"
            class="input"
            type="text"
            placeholder="100"
          />
          <span class="icon is-small is-left">
            <font-awesome-icon icon="dollar-sign" />
          </span>
        </div>
      </div>
      <div class="column control is-one-fifth-desktop is-one-fourth-tablet">
        <label class="label">Desconto</label>
        <div class="control has-icons-left">
          <input
            :value="displayRawMoney(cartItem.discount)"
            @input="setDiscount"
            class="input"
            type="number"
            placeholder="10"
          />
          <span class="icon is-small is-left">
            <font-awesome-icon icon="dollar-sign" />
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  props: {
    cartItem: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      name: this.cartItem.employee ? this.cartItem.employee.name : ''
    };
  },
  computed: {
    filteredDataObj() {
      return this.employees.filter(
        (option) =>
          option.name
            .toString()
            .toLowerCase()
            .indexOf(this.name.toLowerCase()) >= 0
      );
    },
    employees() {
      return this.$store.state.employee.all;
    }
  },
  methods: {
    duration(duration) {
      return moment
        .duration(duration, 's')
        .locale('pt')
        .humanize();
    },

    setEmployee(employee) {
      this.$emit('update-item', {
        ...this.cartItem,
        employee,
        employee_id: employee.id
      });
    },

    setPrice(e) {
      const price = parseInt(e.target.value * 100, 10);
      this.$emit('update-item', { ...this.cartItem, price });
    },

    setDiscount(e) {
      const discount = parseInt(e.target.value * 100, 10);
      this.$emit('update-item', { ...this.cartItem, discount });
    },

    setQuantity(e) {
      const quantity = parseInt(e.target.value, 10);
      this.$emit('update-item', { ...this.cartItem, quantity });
    }
  }
};
</script>

<style lang="scss">
.sale-item {
  .box-header {
    margin-bottom: 0;
  }

  .box-header-title {
    text-align: left;
    margin-bottom: 0;
    padding-bottom: 0.3em;
  }

  .box-header-subtitle {
    text-align: left;
    padding: 0;
    padding-top: 0.5em;
  }

  .item-price {
    padding-bottom: 0.3em;
  }

  .delete-item {
    margin-left: auto;
    margin-right: 0;
    padding-bottom: 0.3em;
    text-align: right;
  }

  .box-content {
    margin-top: 0px;
  }
}
</style>
