import { api } from '@/utils/api-connect';

/* eslint no-param-reassign: "error" */
const product = {
  namespaced: true,
  state: {
    productCategories: []
  },
  mutations: {
    GET_PRODUCT_CATEGORIES(state, productCategories) {
      state.productCategories = productCategories;
    },
    ADD_PRODUCT_CATEGORY(state, newProductCategory) {
      state.productCategories.push(newProductCategory);
    },
    ADD_PRODUCT(state, newProduct) {
      state.productCategories = state.productCategories.map((pc) => {
        if (pc.id === newProduct.product_category_id) {
          const productCategory = pc;
          productCategory.products.push(newProduct);
          return productCategory;
        }
        return pc;
      });
    }
  },
  actions: {
    async getProductCategories(context, payload) {
      const { salon } = payload;
      try {
        const response = await api.get(
          `/salons/${salon.id}/product_categories`
        );
        const productCategories = response.data || [];
        context.commit('getProductCategories', productCategories);
      } catch (error) {
        let errors = {};
        if (error.response) {
          errors = error.response.data || {};
        } else {
          errors.message = error.message;
        }
        throw new Error(errors);
      }
    }
  }
};

export default product;
