export default {
  SALE_REQUEST: 'SALE_REQUEST',
  CHECKOUT_SUCCESS: 'CHECKOUT_SUCCESS',
  CHECKOUT_ERROR: 'CHECKOUT_ERROR',
  GET_SALES: 'GET_SALES',
  ADD_SALE: 'ADD_SALE',

  GET_PRODUCTS: 'GET_PRODUCTS',
  GET_PRODUCT_CATEGORIES: 'GET_PRODUCT_CATEGORIES',
  ADD_PRODUCT_CATEGORY: 'ADD_PRODUCT_CATEGORY',
  ADD_PRODUCT: 'ADD_PRODUCT',
  GET_PRODUCTS_REQUEST: 'GET_PRODUCTS_REQUEST',

  GET_SERVICES: 'GET_SERVICES',
  GET_SERVICES_REQUEST: 'GET_SERVICES_REQUEST',

  GET_REPORTS_ERROR: 'GET_REPORTS_ERROR'
};
