<template>
  <div
    class="mobile-bottom-menu"
    :class="[{ 'is-hidden-desktop': isHiddenDesktop }, classes]"
  >
    <div
      v-if="showButtonOptions"
      class="mobile-action-background"
      @click="showButtonOptions = false"
    />
    <button
      class="button"
      v-if="buttons.length === 1"
      :class="buttons[0].class ? buttons[0].class : 'is-primary'"
      @click="buttonAction(buttons[0].action)"
    >
      <span>
        <font-awesome-icon :icon="buttons[0].icon" />
      </span>
    </button>
    <button
      class="button is-primary"
      v-show="!showButtonOptions && buttons.length > 1"
      @click="showButtonOptions = true"
    >
      <span>
        <font-awesome-icon icon="plus" />
      </span>
    </button>
    <div
      v-show="showButtonOptions"
      class="mobile-buttons"
      v-for="button in buttons"
      :key="button.id"
    >
      <b-tooltip
        :label="button.title"
        position="is-left"
        type="is-black"
        :always="showButtonOptions"
      >
        <button
          class="button"
          :class="button.class ? button.class : 'is-primary'"
          @click="buttonAction(button.action)"
        >
          <span>
            <font-awesome-icon :icon="button.icon" />
          </span>
        </button>
      </b-tooltip>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showButtonOptions: false
    };
  },
  props: {
    buttons: {
      type: Array,
      required: true
    },
    classes: {
      type: String,
      default: ''
    },
    isHiddenDesktop: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    buttonAction(action) {
      this.showButtonOptions = false;
      action();
    }
  }
};
</script>

<style lang="scss" scoped>
.mobile-bottom-menu {
  position: fixed;
  bottom: 21px;
  right: 25px;
  z-index: 12;

  button {
    height: 50px;
    width: 50px;
    padding: 0;
    display: flex;
    border-radius: 50%;
    z-index: 12;
    font-size: 18px;
  }

  .mobile-buttons {
    margin-bottom: 10px;
  }
}
.mobile-action-background {
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: #000000;
  opacity: 0.4;
  z-index: 11;
}
</style>
