<template>
  <div class="client-select">
    <b-autocomplete
      v-model="name"
      :data="filteredDataObj"
      :open-on-focus="true"
      ref="autocomplete"
      field="name"
      icon="search"
      size="is-medium"
      placeholder="Pesquisar Cliente"
      @select="setClient"
    >
      <template slot="header">
        <a>
          <span> Adicionar novo cliente </span>
        </a>
      </template>
      <template slot="empty"
        >Sem resultados para {{ name }}</template
      >
    </b-autocomplete>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { debounce } from 'lodash';

export default {
  data() {
    return {
      name: this.client.name || ''
    };
  },
  props: {
    client: {
      type: Object,
      required: true
    }
  },
  watch: {
    name() {
      this.debounceSearch(this.name);
    }
  },
  computed: {
    ...mapState({
      salon: (state) => state.salon,
      clients: (state) => state.client.all,
      searchedClients: (state) => state.client.searched,
      clientLoading: (state) => state.client.isLoading
    }),
    filteredDataObj() {
      if (this.name) {
        return this.searchedClients;
      }
      return this.clients;
    }
  },
  created() {
    this.debounceSearch = debounce(this.getClients, 200);
  },
  methods: {
    ...mapActions('client', ['searchClients']),

    setClient(client) {
      this.$emit('set-client', client);
    },

    getClients(query) {
      if (query) {
        this.searchClients({ query, salon: this.salon });
      }
    }
  }
};
</script>
