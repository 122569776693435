import { render, staticRenderFns } from "./MobileBottomMenu.vue?vue&type=template&id=581a7576&scoped=true&"
import script from "./MobileBottomMenu.vue?vue&type=script&lang=js&"
export * from "./MobileBottomMenu.vue?vue&type=script&lang=js&"
import style0 from "./MobileBottomMenu.vue?vue&type=style&index=0&id=581a7576&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "581a7576",
  null
  
)

export default component.exports