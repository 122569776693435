var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"calendar"},[_c('div',{staticClass:"calendar-header"},[(_vm.showStaffOnHeader)?_c('div',{staticClass:"header columns is-mobile is-side-gapless"},[_c('div',{staticClass:"column is-1-and-half"}),(_vm.employeeSelected)?[_c('div',{staticClass:"column"},[_vm._v("\n          "+_vm._s(_vm.employeeSelected.name)+"\n        ")])]:_vm._l((_vm.staff),function(s){return _c('div',{key:s.user,staticClass:"column"},[_vm._v("\n          "+_vm._s(s.name)+"\n        ")])})],2):_c('div',{staticClass:"header columns is-mobile is-side-gapless"},[_c('div',{staticClass:"column is-1-and-half"}),_vm._l((_vm.daysOfWeek),function(d){return _c('div',{key:d.format(),staticClass:"column"},[_vm._v("\n        "+_vm._s(d.format('ddd DD'))+"\n      ")])})],2)]),_c('div',{staticClass:"calendar-body"},[_vm._l((_vm.timeRows),function(n){return _c('div',{key:n,staticClass:"appointments columns is-mobile is-side-gapless"},[_c('div',{staticClass:"column is-1-and-half",class:{ 'full-hour': _vm.isFullHour(n) }},[(_vm.isFullHour(n))?_c('span',[_vm._v(_vm._s(_vm.fullHour(n))+"h")]):_vm._e()]),(_vm.showStaffOnHeader)?[(_vm.employeeSelected)?[_c('div',{staticClass:"column is-booking-type",class:{
              'full-hour': _vm.isFullHour(n),
              'is-booked':
                _vm.columnsBooked[_vm.getColumnId(n, _vm.daySelected, _vm.employeeSelected)]
            },style:(!!_vm.columnsBooked[_vm.getColumnId(n, _vm.daySelected, _vm.employeeSelected)]
                ? {
                    backgroundColor:
                      _vm.columnsBooked[
                        _vm.getColumnId(n, _vm.daySelected, _vm.employeeSelected)
                      ].status === 'no-show'
                        ? 'hsl(348, 100%, 61%)'
                        : _vm.columnsBooked[
                            _vm.getColumnId(n, _vm.daySelected, _vm.employeeSelected)
                          ].employee.color,
                    borderLeft: ("2px solid " + (_vm.columnsBooked[_vm.getColumnId(n, _vm.daySelected, _vm.employeeSelected)].employee.borderColor)),
                    opacity:
                      _vm.columnsBooked[
                        _vm.getColumnId(n, _vm.daySelected, _vm.employeeSelected)
                      ].status === 'no-show' ||
                      _vm.columnsBooked[
                        _vm.getColumnId(n, _vm.daySelected, _vm.employeeSelected)
                      ].status === 'completed'
                        ? 0.6
                        : 1
                  }
                : {})},[(
                !!_vm.columnsBooked[_vm.getColumnId(n, _vm.daySelected, _vm.employeeSelected)]
              )?[_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                  content: _vm.tooltipContent(
                    _vm.columnsBooked[
                      _vm.getColumnId(n, _vm.daySelected, _vm.employeeSelected)
                    ]
                  )
                }),expression:"{\n                  content: tooltipContent(\n                    columnsBooked[\n                      getColumnId(n, daySelected, employeeSelected)\n                    ]\n                  )\n                }"}],staticClass:"booking-description"},[_c('button',{staticClass:"has-text-white has-text-weight-semibold",on:{"click":function($event){_vm.$emit(
                      'open-modal-checkout',
                      _vm.columnsBooked[
                        _vm.getColumnId(n, _vm.daySelected, _vm.employeeSelected)
                      ]
                    )}}},[_vm._v("\n                  "+_vm._s(_vm.bookingInfo[
                      _vm.getColumnId(n, _vm.daySelected, _vm.employeeSelected)
                    ])+" \n                ")])])]:[_c('span',{on:{"click":function($event){_vm.$emit(
                    'open-new-appointment',
                    _vm.formattedRowTime(_vm.daySelected, n),
                    _vm.employeeSelected
                  )}}},[_vm._v("\n                 \n              ")])]],2)]:_vm._l((_vm.staff),function(s){return _c('div',{key:s.id,staticClass:"column is-booking-type",class:{
              'full-hour': _vm.isFullHour(n),
              'is-booked': !!_vm.columnsBooked[_vm.getColumnId(n, _vm.daySelected, s)]
            },style:(!!_vm.columnsBooked[_vm.getColumnId(n, _vm.daySelected, s)]
                ? {
                    backgroundColor:
                      _vm.columnsBooked[_vm.getColumnId(n, _vm.daySelected, s)].status ===
                      'no-show'
                        ? 'hsl(348, 100%, 61%)'
                        : _vm.columnsBooked[_vm.getColumnId(n, _vm.daySelected, s)]
                            .employee.color || '#4d5795',
                    borderLeft: ("2px solid " + (_vm.columnsBooked[_vm.getColumnId(n, _vm.daySelected, s)].employee.borderColor)),
                    opacity:
                      _vm.columnsBooked[_vm.getColumnId(n, _vm.daySelected, s)].status ===
                        'no-show' ||
                      _vm.columnsBooked[_vm.getColumnId(n, _vm.daySelected, s)].status ===
                        'completed'
                        ? 0.6
                        : 1
                  }
                : {})},[(_vm.columnsBooked[_vm.getColumnId(n, _vm.daySelected, s)])?[_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                  content: _vm.tooltipContent(
                    _vm.columnsBooked[_vm.getColumnId(n, _vm.daySelected, s)]
                  )
                }),expression:"{\n                  content: tooltipContent(\n                    columnsBooked[getColumnId(n, daySelected, s)]\n                  )\n                }"}],staticClass:"booking-description has-text-white has-text-weight-semibold"},[_c('button',{staticClass:"has-text-white has-text-weight-semibold",on:{"click":function($event){_vm.$emit(
                      'open-modal-checkout',
                      _vm.columnsBooked[_vm.getColumnId(n, _vm.daySelected, s)]
                    )}}},[_vm._v("\n                  "+_vm._s(_vm.bookingInfo[_vm.getColumnId(n, _vm.daySelected, s)])+" \n                ")])])]:[_c('span',{on:{"click":function($event){_vm.$emit(
                    'open-new-appointment',
                    _vm.formattedRowTime(_vm.daySelected, n),
                    s
                  )}}},[_vm._v(" ")])]],2)})]:_vm._l((_vm.daysOfWeek),function(d){return _c('div',{key:_vm.getColumnId(n, d),staticClass:"column is-booking-type",class:{
            'full-hour': _vm.isFullHour(n),
            'is-booked': !!_vm.columnsBooked[_vm.getColumnId(n, d)]
          },style:(!!_vm.columnsBooked[_vm.getColumnId(n, d)]
              ? {
                  backgroundColor:
                    _vm.columnsBooked[_vm.getColumnId(n, d)].status === 'no-show'
                      ? 'hsl(348, 100%, 61%)'
                      : _vm.columnsBooked[_vm.getColumnId(n, d)].employee.color ||
                        '#4d5795',
                  borderLeft: ("2px solid " + (_vm.columnsBooked[_vm.getColumnId(n, d)].employee.borderColor)),
                  opacity:
                    _vm.columnsBooked[_vm.getColumnId(n, d)].status === 'no-show' ||
                    _vm.columnsBooked[_vm.getColumnId(n, d)].status === 'completed'
                      ? 0.6
                      : 1
                }
              : {})},[(!!_vm.columnsBooked[_vm.getColumnId(n, d)])?[_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                content: _vm.tooltipContent(_vm.columnsBooked[_vm.getColumnId(n, d)])
              }),expression:"{\n                content: tooltipContent(columnsBooked[getColumnId(n, d)])\n              }"}],staticClass:"booking-description has-text-white has-text-weight-semibold"},[_c('button',{staticClass:"has-text-white has-text-weight-semibold",on:{"click":function($event){_vm.$emit(
                    'open-modal-checkout',
                    _vm.columnsBooked[_vm.getColumnId(n, d)]
                  )}}},[_vm._v("\n                "+_vm._s(_vm.bookingInfo[_vm.getColumnId(n, d)] || '')+" \n              ")])])]:[_c('span',{on:{"click":function($event){_vm.$emit(
                  'open-new-appointment',
                  _vm.formattedRowTime(d, n),
                  _vm.employeeSelected
                )}}},[_vm._v(" ")])]],2)})],2)}),_c('div',{staticClass:"bottom columns is-mobile"})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }